import 'core-js/es/array/from'

import '../images/logo'
import '../stylesheets/application'

import Rails from 'rails-ujs'
import M from 'materialize-css'

Rails.start()

document.addEventListener("DOMContentLoaded", () => {
  M.updateTextFields()
})

